import './list.css'
import UserLayout from '../../common/userLayout'
import Nav from '../../common/nav'
import {
    EditOutlined, EllipsisOutlined, SettingOutlined
} from '@ant-design/icons';
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Layout, Card, Avatar, Button, message, Row, Col } from 'antd';

const BlindboxList = () => {
    const { Meta } = Card;
    const [blindboxInfo, setBlindboxInfo] = useState([])
    useEffect(() => {
        axios.post('/api/getBlindboxList').then(function (res) {
            if (res.status === 200) {
                console.log(res)
                setBlindboxInfo(res.data.data)

            } else {
                message.error('获取盲盒列表失败。');
            }
        })

    }, [])
    const publish = (id) => () => {
        axios.post('/api/releaseBlindbox', {
            "blindbox_id": id
        }).then(function (res) {
            if (res.status === 200) {
                console.log(res)
                message.success('发布成功。')
                axios.post('/api/getBlindboxList').then(function (respone) {
                    if (res.status === 200) {
                        console.log(respone)
                        setBlindboxInfo(respone.data.data)

                    } else {
                        message.error('获取盲盒列表失败。');
                    }
                })

            } else {
                message.error('发布失败');
            }
        })
    }
    return (<div>
        <Layout>
            <Nav index="5" pindex="sub2" />
            <UserLayout>
                <div className="blindboxWrapper">
                    <Row>
                        {blindboxInfo.map(e => (
                            <Col span={6} key={e.blindbox_id}><Card
                                style={{ width: 240, 'marginBottom': '20px' }}
                                key={e.blindbox_id}
                                cover={
                                    <img
                                        alt={e.name}
                                        src={`https://image.unemeta.com/${e.show_cover}`}
                                    />
                                }
                                actions={[
                                    <div>{e.is_release === 1 ? (<div onClick={publish(e.blindbox_id)}>发布</div>) : (<div style={{ 'color': '#ccc' }}>已发布</div>)}</div>,
                                    <div>{e.is_release === 1 ? (<Link to={{
                                        pathname: `/blindbox/edit`,
                                        search: `?id=${e.blindbox_id}`
                                    }}>编辑</Link>) : (<div style={{ 'color': '#ccc' }}>编辑</div>)}</div>,
                                ]}
                            >
                                <Meta
                                    avatar={<Avatar src={`https://image.unemeta.com/${e.collection_logo_url}`} />}
                                    title={e.collection_name}
                                    style={{ 'height': '50px', 'overflow': 'hidden' }}
                                    description={e.collection_description}
                                />
                            </Card></Col>


                        ))}
                    </Row>
                </div>
            </UserLayout>
        </Layout>

    </div >)
}
export default BlindboxList