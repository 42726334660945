import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './pages/login';
import UserList from './pages/user/list';
import TopicCreate from './pages/topic/create';
import TopicEdit from './pages/topic/edit';
import BlindboxCreate from './pages/blindbox/create';
import BlindboxList from './pages/blindbox/list';
import BlindboxEdit from './pages/blindbox/edit';
import TopicList from './pages/topic/list';
import Cookies from 'js-cookie';
import './App.css'
function App() {
  // let Navigate = useNavigate();
  console.log(Cookies.get('session_id'))
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/user/list' element={<UserList />} />
        <Route path='/login' element={<Login />} />
        <Route path='/topic/create' element={<TopicCreate />} />
        <Route path='/topic/list' element={<TopicList />} />
        <Route path='/topic/edit' element={<TopicEdit />} />
        <Route path='/blindbox/create' element={<BlindboxCreate />} />
        <Route path='/blindbox/list' element={<BlindboxList />} />
        <Route path='/blindbox/edit' element={<BlindboxEdit />} />
      </Routes>
    </BrowserRouter>
  )
}


export default App;
