import './nav.css'
import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
const { SubMenu } = Menu;

const { Header, Sider, Content } = Layout;
const Nav = (props) => {
    return (<Sider>
        <div className="logo"><img className="logoImg" src="https://lh3.googleusercontent.com/qkkk3p1DmB4C8Q6uS4nRz9mytJjZh91g-M4vFPFAqHj-qQZowTqljyayKTpHAT7qaZbHC949Q40HcjDvxyeXuZ2BLqhzHF6Cj4BlJrSn-Mw6=s96" /></div>
        <Menu theme="dark" mode="inline" defaultOpenKeys={[props.pindex]} defaultSelectedKeys={props.index}>
            <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/user/list">
                    用户管理
                </Link>

            </Menu.Item>
            <SubMenu key="sub1" icon={<FileTextOutlined />} title="专题管理">
                <Menu.Item key="2"><Link to="/topic/create">新建专题</Link></Menu.Item>
                <Menu.Item key="3"><Link to="/topic/list">专题列表</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<FileTextOutlined />} title="盲盒管理">
                <Menu.Item key="4"><Link to="/blindbox/create">新建盲盒</Link></Menu.Item>
                <Menu.Item key="5"><Link to="/blindbox/list">盲盒列表</Link></Menu.Item>
            </SubMenu>
        </Menu>
    </Sider>)
}

export default Nav;