import React, { useCallback, useState, useEffect, memo } from "react";
import { useDropzone } from 'react-dropzone'
import axios from 'axios';
import { FileImageFilled } from '@ant-design/icons';
import './uploadImg.css'
import {
    message
} from 'antd';
let UploadBindboxImg = (props) => {
    const [imgPreview, setImgPreview] = useState(null);
    const [imgShow, setImgShow] = useState(true);
    const { urlToken, preview } = props;
    useEffect(() => {
        if (!(preview === '' || preview === undefined)) {
            setImgShow(false)
            setImgPreview(preview)
            urlToken(preview)
        }

    }, []);
    const onDrop = useCallback(acceptedFiles => {
        let formData = new FormData();
        formData.append('filename', acceptedFiles[0].name)
        formData.append("file", acceptedFiles[0]);
        let config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/uploadBlindboxImage', formData, config).then(function (response) {
            console.log(response);
            if (response.status == 200) {
                setImgPreview(response.data.ipfs_url)
                urlToken(response.data)
                setImgShow(false)
            }
        }).catch(err => {
            message.error(err.response.data.message)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })
    return (
        <div className="uploadImg">
            <div className="uploadLabel" {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ 'width': props.styles.width, 'height': props.styles.height, 'borderRadius': props.styles.borderRadius }} className="uploadBtn">
                    {imgShow && <FileImageFilled className="uploadIcon" />}
                    {!imgShow && <span>{imgPreview}</span>}
                </div>
            </div></div>)
}
export default memo(UploadBindboxImg);