import './list.css'
import UserLayout from '../../common/userLayout'
import Nav from '../../common/nav'
import { Pagination } from 'antd';
import {
    CheckCircleFilled,
    InfoCircleFilled
} from '@ant-design/icons';
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Layout, Table, Input, Button, message } from 'antd';
const { Search } = Input;


const UserList = () => {
    const [dataSource, setDataSource] = useState([]);
    const [wallet, setWallet] = useState('');
    const [pageTotal, setTotal] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const init = (page) => {
        let formData = {
            "wallet_address": '',
            "now_page_num": page,
            "page_limit_num": 10
        }
        const config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/userList', formData, config).then(function (response) {
            if (response.status === 200) {
                console.log(response)
                let data = [];
                setTotal(response.data.total_user_num)
                response.data.user_list_info.map((e, v) => {
                    data.push({
                        'key': v,
                        'wallet_address': e.wallet_address,
                        'username': e.username,
                        'bio': e.bio,
                        'profile_image': e.profile_image,
                        'auth_status': e.auth_status,
                        'created_at': e.created_at,
                        'favorites_num': e.favorites_num,
                        'followers': e.followers,
                        'following': e.following,
                        'integral_num': e.integral_num,
                    })
                })
                setDataSource(data)
            } else {
                message.success('获取用户列表失败');
            }
        })
    }
    useEffect(() => {
        init(1)
    }, [])

    const columns = [
        {
            title: 'profile',
            dataIndex: 'profile_image',
            key: 'profile_image',
            render: (data) => {
                let pimg = '';
                if (data === '') {
                    pimg = 'https://lh3.googleusercontent.com/urgltXHtgZ7KgcwMMjkmkteO7m3ZbK_8OWkx6l-QAPH4gKxN0gxooCiAcVcgW0MLygLnFK_c1iuolyFG3PtFARSWsvrZaGQe4C5IJ-PsAOczvQ'
                } else {
                    pimg = `https://image.unemeta.com/${data}`
                }
                return <span><img className="profileImage" src={pimg} /></span>
            }
        },
        {
            title: 'username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'followers',
            dataIndex: 'followers',
            key: 'followers',
        },
        {
            title: 'following',
            dataIndex: 'following',
            key: 'following',
        },
        {
            title: 'favorite',
            dataIndex: 'favorites_num',
            key: 'favorites_num',
        },
        {
            title: 'integral',
            dataIndex: 'integral_num',
            key: 'integral_num',
        },
        {
            title: 'wallet address',
            dataIndex: 'wallet_address',
            key: 'wallet_address',
            render: (data) => <span className="walletAddress">{data}</span>
        },
        {
            title: 'status',
            dataIndex: 'auth_status',
            key: 'auth_status',
            render: (status) => {
                if (status === 1) {
                    return (<div><InfoCircleFilled className="beCertifyIcon" />待认证</div>)
                } else {
                    return (<div><CheckCircleFilled className="hadCertifyIcon" />已认证</div>)
                }

            }
        },
        {
            title: 'link',
            dataIndex: 'wallet_address',
            key: 'link',
            render: (status) => {
                return (<a href={`https://home.kelea.xyz//user/${status}`} target="_blank">查看</a>)
            }
        },
        {
            title: 'action',
            key: 'y',
            render: (data) => {
                if (data.auth_status === 1) {
                    return (<div><Button type="primary" onClick={certify(data.wallet_address, 2)}>认证通过</Button></div>)
                } else {
                    return (<div><Button type="primary" onClick={certify(data.wallet_address, 1)}>取消认证</Button></div>)
                }
            }
        },
    ];

    const certify = (wallets, status) => () => {
        // wallet

        console.log(wallets, status, dataSource)
        let formData = {
            "wallet_address": wallets,
            "auth_status": status
        }
        const config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/changeAuthStatus', formData, config).then(function (res) {
            if (res.status === 200) {
                message.success('审核成功。');
                dataSource.map(useritem => {
                    let statu = useritem.auth_status;
                    if (useritem.wallet_address === wallets) {
                        useritem.auth_status = statu == 1 ? 2 : 1;
                    }
                })
                let newArr = [].concat(dataSource)
                setDataSource(newArr)
                // axios.post('/api/userList', {
                //     "wallet_address": wallet,
                // }, config).then(function (response) {
                //     if (response.status === 200) {
                //         init();
                //         // let data = [];
                //         // response.data.data.map((e, v) => {
                //         //     data.push({
                //         //         'key': v,
                //         //         'wallet_address': e.wallet_address,
                //         //         'username': e.username,
                //         //         'bio': e.bio,
                //         //         'profile_image': e.profile_image,
                //         //         'auth_status': e.auth_status,
                //         //         'created_at': e.created_at,
                //         //         'favorites_num': e.favorites_num,
                //         //         'followers': e.followers,
                //         //         'following': e.following,
                //         //         'integral_num': e.integral_num,

                //         //     })
                //         // })
                //         // setDataSource(data)
                //     }
                // })
            } else {
                message.error('审核失败。');
            }
        })
    }
    const onSearch = value => {
        setWallet(value)
        let formData = {
            "wallet_address": value,
            "now_page_num": 1,
            "total_user_num": 10
        }
        const config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/userList', formData, config).then(function (response) {
            if (response.status === 200) {
                message.success('查询成功');
                let data = [];
                response.data.user_list_info.map((e, v) => {
                    data.push({
                        'key': v,
                        'wallet_address': e.wallet_address,
                        'username': e.username,
                        'bio': e.bio,
                        'profile_image': e.profile_image,
                        'auth_status': e.auth_status,
                        'created_at': e.created_at,
                        'favorites_num': e.favorites_num,
                        'followers': e.followers,
                        'following': e.following,
                        'integral_num': e.integral_num,
                    })
                })
                setCurPage(1)
                setTotal(response.data.total_user_num)
                setDataSource(data)
            } else {
                message.success('查询失败');
            }
        })
    };
    const pageChange = (e) => {
        setCurPage(e)
        init(e)
    }
    return (<div className="userList">
        <Layout>
            <Nav index="1" />
            <UserLayout>
                <div className="searchWrapper">
                    <Search
                        placeholder="search wallet address"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={onSearch}
                    />
                </div>

                <Table dataSource={dataSource} pagination={false} columns={columns} />
                <Pagination
                    className="pageWrapper"
                    current={curPage}
                    total={pageTotal}
                    onChange={pageChange}
                    showSizeChanger={false} />
            </UserLayout>
        </Layout>

    </div>)
}
export default UserList