import { useState, useEffect } from 'react'
import UserLayout from '../../common/userLayout'
import { ContentUtils } from 'braft-utils'
import Nav from '../../common/nav'
import './create.css'
import UploadImg from '../../components/uploadImg'
import UploadBindboxImg from '../../components/uploadblindImg'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import axios from 'axios'
// import Edit from '../edit'
import '@wangeditor/editor/dist/css/style.css'
import { createEditor, createToolbar, IEditorConfig, IDomEditor, IToolbarConfig, Boot } from '@wangeditor/editor'
// import { IButtonMenu } from '@wangeditor/core'
import { useLocation, useParams } from "react-router-dom";
import {
    CheckCircleFilled,
} from '@ant-design/icons';
import {
    Layout, Modal,
    message, Select, Divider, Space, Button, Radio,
    Table, Input, InputNumber, Popconfirm, Form, Typography
} from 'antd';

const { Search } = Input;
const { Option } = Select;

const BlindboxCreate = (props) => {
    const [logoUrl, setLogoUrl] = useState(null);
    const [bannerUrl, setBannerUrl] = useState(null);
    const [featureUrl, setFeatureUrl] = useState(null);
    const [collectionUrl, setCollectionUrl] = useState(null);
    const [addTableBlo, setAddTableBlo] = useState(false)
    const [dataSource, setDataSource] = useState([]);
    const [blindboxUrl, setBlindboxUrl] = useState(null)
    const [currencyInfo, setCurrencyInfo] = useState([])//货币类型
    const [blindboxId, setBlindboxId] = useState(null)//
    // const originData = [];
    const saveBlindboxData = async (values) => {
        console.log(logoUrl, bannerUrl, featureUrl, collectionUrl)
        console.log(values)
        console.log(dataSource)
        if (logoUrl == null || bannerUrl == null || featureUrl == null || collectionUrl == null) {
            message.warning('图片上传不能为空');
            return;
        }
        axios.post('/api/createBlindbox', {
            "collection_logo_url": logoUrl,
            "collection_featured_image": featureUrl,
            "collection_banner_image": bannerUrl,
            "collection_name": values.collection_name,
            "collection_description": values.collection_description,
            "show_cover": collectionUrl,
            "name": values.name,
            "price": parseFloat(values.price),
            "currency_type": values.currency_type,
            "description": values.description,
            "blindbox_buy_address": values.blindbox_buy_address,
            "blindbox_buy_address_erc20": values.blindbox_buy_address_erc20,
            "type": values.type,
            "blindbox_material_info": dataSource
        }).then(function (res, err) {
            console.log(res)
            if (res.status === 200) {
                setBlindboxId(res.data.data);
                message.success('保存盲盒成功。');
            } else {
                message.error('保存盲盒失败。');
            }
            console.log(err)
        }).catch(err => {
            console.log(err)
            message.error('参数检测有误。');
        })
    }

    const columns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'name_cn',
            dataIndex: 'name_cn',
            key: 'name_cn',
        },
        {
            title: 'introduction',
            dataIndex: 'introduction',
            key: 'introduction',
        },
        {
            title: 'level',
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: 'probability',
            dataIndex: 'probability',
            key: 'probability',
        },
        {
            title: 'limit',
            dataIndex: 'limit',
            key: 'limit',
        },
        {
            title: 'ipfs_url',
            dataIndex: 'ipfs_url',
            key: 'ipfs_url',
        },
        {
            title: 'weight',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'attribute',
            dataIndex: 'attribute',
            key: 'attribute',
        },
        {
            title: 'action',
            key: 'action',
            render: (data) => {
                return < Button onClick={() => deleteMaterial(data)}> Delete</Button >
            }
        }
    ];
    const deleteMaterial = (data) => {

    }
    useEffect(() => {

        axios.post('/api/getCurrencyInfo').then(function (res) {
            if (res.status === 200) {
                console.log(res)

                setCurrencyInfo(res.data.data)

            } else {
                message.error('获取货币类型失败。');
            }
        })


        // console.log(location.href)
    }, [])
    const tableAdd = () => {
        setAddTableBlo(true)
        // const newData = {
        //     key: count,
        //     name: `Edward King ${count}`,
        //     age: '32',
        //     address: `London, Park Lane no. ${count}`,
        // };
        // dataSource: [...dataSource, newData],
    };
    const addTableCell = (values) => {
        values.key = Date.parse(new Date())
        values.ipfs_url = blindboxUrl.ipfs_url;
        values.google_url = blindboxUrl.google_url;
        values.probability = parseFloat(values.probability);
        values.limit = parseInt(values.limit);
        values.weight = parseInt(values.weight);
        values.status = parseInt(values.status);
        setAddTableBlo(false)
        setDataSource([...dataSource, values])
    }
    const addTableCancel = () => {
        setAddTableBlo(false)
    }
    const downloadExcel = () => {
        console.log(blindboxId)
        if (blindboxId === null) {
            message.warning('请先保存后再下载');
            return
        }
        window.open(`https://apibs.unemeta.com/exportBlindboxInfo?blindbox_id=${blindboxId}`, '_blank');
    }
    return (<div>
        <Layout>
            <Nav index="4" pindex="sub2" />
            <UserLayout>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={saveBlindboxData}
                    autoComplete="off"
                    layout={"vertical"}
                >
                    <div className="createItem">
                        <div className="createItemTitle"><span style={{ 'color': '#ff4d4f' }}>*</span>Logo 图片</div>
                        <UploadImg styles={{ 'width': '20rem', 'height': '160px', 'borderRadius': '1rem' }} urlToken={e => setLogoUrl(e)} />
                    </div>
                    <div className="createItem">
                        <div className="createItemTitle"><span style={{ 'color': '#ff4d4f' }}>*</span>Featured 图片</div>
                        <UploadImg styles={{ 'width': '20rem', 'height': '160px', 'borderRadius': '1rem' }} urlToken={e => setFeatureUrl(e)} />
                    </div>
                    <div className="createItem">
                        <div className="createItemTitle"><span style={{ 'color': '#ff4d4f' }}>*</span>Banner 图片</div>
                        <UploadImg styles={{ 'width': '20rem', 'height': '160px', 'borderRadius': '1rem' }} urlToken={e => setBannerUrl(e)} />
                    </div>
                    <Form.Item
                        label="盲盒集合名称"
                        name="collection_name"
                        rules={[{ required: true, message: 'Cannot be empty' }]}

                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                        label="盲盒集合描述"
                        name="collection_description"
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <div className="createItem">
                        <div className="createItemTitle"><span style={{ 'color': '#ff4d4f' }}>*</span>盲盒展示封面</div>
                        <UploadImg styles={{ 'width': '20rem', 'height': '160px', 'borderRadius': '1rem' }} urlToken={e => setCollectionUrl(e)} />
                    </div>
                    <Form.Item
                        label="盲盒名称"
                        name="name"
                        rules={[{ required: true, message: 'Cannot be empty' }]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                        label="盲盒描述"
                        name="description"
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        name="currency_type"
                        label="盲盒售卖币种"
                        hasFeedback
                        rules={[{ required: true, message: 'Please select currency type' }]}
                    >
                        <Select placeholder="Please select currency type">
                            {currencyInfo.map((e, v) => (
                                <Option key={v} value={e.currency_type}>{e.currency_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="盲盒价格"
                        name="price"
                        rules={[{ required: true, message: 'Cannot be empty' }]}
                    >
                        <InputNumber min="0" keyboard={false} style={{ 'width': '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label="盲盒类型"
                        hasFeedback
                        rules={[{ required: true, message: 'Please select blindbox type' }]}
                    >
                        <Select placeholder="Please select blindbox type">
                            {/* <Option value={1}>非盲盒</Option> */}
                            <Option value={2}>原始盲盒2</Option>
                            <Option value={3}>新盲盒3</Option>
                            <Option value={4}>新盲盒4</Option>
                        </Select>
                    </Form.Item>
                    <Divider plain>北京的参数</Divider>
                    <Form.Item
                        label="盲盒原生代币合约地址"
                        name="blindbox_buy_address"
                    >
                        <Input type="text" />
                    </Form.Item>
                    <Form.Item
                        label="盲盒ERC20合约地址"
                        name="blindbox_buy_address_erc20"
                    >
                        <Input type="text" />
                    </Form.Item>
                    <div className="createItem">
                        <div className="createItemTitle">上传素材</div>
                        <Button
                            onClick={tableAdd}
                            type="primary"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            新增一行
                        </Button>
                        <Button
                            onClick={downloadExcel}
                            type="primary"
                            style={{
                                'marginLeft': '19px',
                                'marginBottom': 16,
                            }}
                        >
                            下载素材EXCEL
                        </Button>
                        <Table scroll={{ x: 500 }} dataSource={dataSource} columns={columns} pagination={false} />
                    </div>
                    <Space size={30}>
                        <Button size={'large'} type="primary" htmlType="submit">保存</Button>
                    </Space>

                </Form>


                <Modal title="新增素材" visible={addTableBlo}
                    onCancel={addTableCancel}
                    footer={null}
                >
                    <Form
                        name="basic"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={addTableCell}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="name"
                            name="name"
                            rules={[{ required: true, message: 'Cannot be empty' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="name_cn"
                            name="name_cn"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="introduction"
                            name="introduction"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="level"
                            name="level"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="probability"
                            name="probability"
                            rules={[{ required: true, message: 'Cannot be empty' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="limit"
                            name="limit"
                            rules={[{ required: true, message: 'Cannot be empty' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="ipfs_url"
                            rules={[{ required: true, message: 'Cannot be empty' }]}
                        >

                            <UploadBindboxImg styles={{ 'width': '260px', 'height': '40px', 'border': 'none' }} urlToken={e => setBlindboxUrl(e)} />
                        </Form.Item>
                        <Form.Item
                            label="weight"
                            name="weight"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="status"
                            label="status"
                            rules={[{ required: true, message: 'Please pick an item!' }]}
                        >
                            <Radio.Group>
                                <Radio.Button value="1">有效</Radio.Button>
                                <Radio.Button value="2">无效</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="attribute"
                            name="attribute"
                            rules={[{ required: true, message: 'Cannot be empty' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                新增素材
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </UserLayout>
        </Layout>

    </div >)
}

export default BlindboxCreate