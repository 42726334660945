import './list.css'
import UserLayout from '../../common/userLayout'
import Nav from '../../common/nav'
import {
    CheckCircleFilled,
    InfoCircleFilled
} from '@ant-design/icons';
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Layout, Table, Input, Button, message, Space, Pagination } from 'antd';
const { Search } = Input;



const UserList = () => {
    const [dataSource, setDataSource] = useState([]);
    const [wallet, setWallet] = useState('');
    const [pageTotal, setTotal] = useState(0);
    const [curPage, setCurPage] = useState(1);
    useEffect(() => {
        init(1)
    }, [])
    const init = (cursPage) => {
        axios.post('/api/getAlbumList', {
            'now_page_num': cursPage,
            'page_limit_num': 10
        }).then(function (res) {
            if (res.status === 200) {
                console.log(res)
                let data = [];
                res.data.album_list.map((e, v) => {
                    data.push({
                        'key': e.album_id,
                        'album_id': e.album_id,
                        'content': e.content,
                        'banner_image': e.banner_image,
                        'recommend_image': e.recommend_image,
                        'created_at': e.created_at,
                        'title': e.title,
                        'status': e.status
                    })
                })
                setDataSource(data)
                setCurPage(cursPage)
                setTotal(res.data.total_user_num)
            } else {
                message.error('获取专题列表失败。');
            }
        })
    }
    const columns = [
        {
            title: 'banner',
            dataIndex: 'banner_image',
            key: 'banner_image',
            render: (data) => <span><img className="ablumImages" src={`${data}`} /></span>
        },
        {
            title: 'recommend',
            dataIndex: 'recommend_image',
            key: 'recommend_image',
            render: (data) => <span><img className="ablumImages" src={`${data}`} /></span>
        },
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'created time',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'link',
            key: 'album_ids',
            render: (data) => {
                if (data.status === 2) {
                    return (<a target="_blank" href={`https://www.unemeta.com/album/${data.album_id}`}>查看</a>)
                }
            }
        },
        {
            title: 'action',
            key: 'y',
            render: (data) => {
                if (data.status === 1) {
                    return (<Space size={10}>
                        <Button size="small" onClick={certify(1, data.album_id)}>发布</Button>
                        <Button size="small" onClick={ablumDelete(data.album_id)}>删除</Button>
                        <Link to={{
                            pathname: `/topic/edit`,
                            search: `?id=${data.album_id}`
                        }}>编辑</Link>
                        {/* <Button size="small" onClick={ablumEdit(data)}>编辑</Button>
                        <Button size="small" onClick={ablumDelete(data)}>删除</Button> */}
                    </Space>)
                } else {
                    return (<Space size={10}><Button type="primary" onClick={certify(2, data.album_id)}>下架</Button>
                        {/* <Button size="small" onClick={ablumEdit(data)}>编辑</Button>
                        <Button size="small" onClick={ablumDelete(data)}>删除</Button> */}
                    </Space>)
                }
            }
        },
    ];
    const certify = (status, id) => () => {
        let formData = {
            "album_id": id
        }
        const config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/albumChangeStatus', formData, config).then(function (resp) {
            if (resp.status === 200) {
                message.success('操作成功。');
                dataSource.map(useritem => {
                    let statu = useritem.status;
                    console.log(useritem.album_id, id, statu)
                    if (useritem.album_id === id) {
                        useritem.status = statu == 1 ? 2 : 1;
                    }
                })
                let newArr = [].concat(dataSource)
                setDataSource(newArr)
                // axios.post('/api/getAlbumList').then(function (res) {
                //     if (res.status === 200) {
                //         console.log(res)
                //         let data = [];
                //         res.data.data.map((e, v) => {
                //             data.push({
                //                 'key': e.album_id,
                //                 'album_id': e.album_id,
                //                 'content': e.content,
                //                 'banner_image': e.banner_image,
                //                 'recommend_image': e.recommend_image,
                //                 'created_at': e.created_at,
                //                 'title': e.title,
                //                 'status': e.status
                //             })
                //         })
                //         setDataSource(data)

                //     } else {
                //         message.error('获取专题列表失败。');
                //     }
                // })
            } else {
                message.error('操作失败。');
            }
        })
    }

    const ablumEdit = (data) => () => {

    }
    const ablumDelete = (id) => () => {
        axios.post('/api/deleteAlbum', {
            'album_id': id
        }).then(function (resp) {
            if (resp.status === 200) {
                message.success('删除成功。');
                let newArr = [].concat(dataSource)
                console.log(newArr)
                newArr.forEach((useritem, v) => {
                    if (useritem.album_id === id) {
                        newArr.splice(v, 1)
                    }
                })
                console.log(newArr)
                setDataSource(newArr)
                // dataSource.map(useritem => {
                //     let statu = useritem.auth_status;
                //     if (useritem.wallet_address === wallets) {
                //         useritem.auth_status = statu == 1 ? 2 : 1;
                //     }
                // })
                // let newArr = [].concat(dataSource)
                // setDataSource(newArr)
                // axios.post('/api/getAlbumList').then(function (res) {
                //     if (res.status === 200) {
                //         console.log(res)
                //         let data = [];
                //         res.data.data.map((e, v) => {
                //             data.push({
                //                 'key': e.album_id,
                //                 'album_id': e.album_id,
                //                 'content': e.content,
                //                 'banner_image': e.banner_image,
                //                 'recommend_image': e.recommend_image,
                //                 'created_at': e.created_at,
                //                 'title': e.title,
                //                 'status': e.status
                //             })
                //         })
                //         setDataSource(data)

                //     } else {
                //         message.error('获取专辑列表失败。');
                //     }
                // })
            } else {
                message.error('删除失败。');
            }
        })
    }
    const pageChange = (e) => {
        setCurPage(e)
        init(e)
    }
    return (<div className="topicList">
        <Layout>
            <Nav index="3" pindex="sub1" />
            <UserLayout>

                <Table dataSource={dataSource} pagination={false} columns={columns} />
                <Pagination
                    className="pageWrapper"
                    current={curPage}
                    total={pageTotal}
                    onChange={pageChange}
                    showSizeChanger={false} />
            </UserLayout>
        </Layout>

    </div>)
}
export default UserList