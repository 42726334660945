import React, { useCallback, useState, useEffect, memo } from "react";
import { useDropzone } from 'react-dropzone'
import axios from 'axios';
import { FileImageFilled } from '@ant-design/icons';
import './uploadImg.css'
import {
    message
} from 'antd';
let UploadImg = (props) => {
    const [imgPreview, setImgPreview] = useState(null);
    const [imgShow, setImgShow] = useState(true);
    const { urlToken, preview } = props;

    useEffect(() => {
        console.log(preview)
        if (!(preview === '' || preview === undefined || preview === null)) {
            setImgShow(false)
            setImgPreview(preview)
            urlToken(preview)
        }

    }, [preview]);
    const onDrop = useCallback(acceptedFiles => {
        let formData = new FormData();
        formData.append('filename', acceptedFiles[0].name)
        formData.append("file", acceptedFiles[0]);
        console.log(formData)
        let config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/upLoadImage', formData, config).then(function (response) {
            console.log(response);
            if (response.status == 200) {
                setImgPreview(response.data.data)
                urlToken(response.data.data)
                setImgShow(false)
                message.success('上传成功')
            } else {
                message.error('上传失败')
            }
        }).catch(err => {
            message.error(err.response.data.message)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })
    return (
        <div className="uploadImg">
            <div className="uploadLabel" {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ 'width': props.styles.width, 'height': props.styles.height, 'borderRadius': props.styles.borderRadius }} className="uploadBtn">
                    {imgShow && <FileImageFilled className="uploadIcon" />}
                    {!imgShow && <img className="uploadPreview" src={`${imgPreview}`} />}
                </div>
            </div></div>)
}
export default memo(UploadImg);