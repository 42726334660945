import { useState, useEffect } from 'react'
import UserLayout from '../../common/userLayout'
import { ContentUtils } from 'braft-utils'
import Nav from '../../common/nav'
import './create.css'
import UploadImg from '../../components/uploadImg'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import axios from 'axios'
// import Edit from '../edit'
import '@wangeditor/editor/dist/css/style.css'
import { createEditor, createToolbar, IEditorConfig, IDomEditor, IToolbarConfig, Boot } from '@wangeditor/editor'
// import { IButtonMenu } from '@wangeditor/core'

import {
    CheckCircleFilled,
} from '@ant-design/icons';
import { Layout, Modal, Input, Button, message, Upload, Form, Checkbox, Space } from 'antd';
const { Search } = Input;
const TopicCreate = () => {
    const [bannerUrl, setBannerUrl] = useState(null);
    const [featureUrl, setFeatureUrl] = useState(null);
    const [title, setTitle] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null))
    const [insertNFTVisible, setInsertNFTVisible] = useState(false);
    const [insertNFTHtml, setInsertNFTHtml] = useState(null);
    const [checkedNFT, setCheckedNFT] = useState('')
    const [editora, setEditora] = useState(null);
    const [NFTID, setNFTID] = useState('')
    const [NFTLink, setNFTLink] = useState('')
    const [previewVisible, setPreviewVisible] = useState(false);//预览是否显示
    const [previewHtml, setPreviewHtml] = useState('');//预览内容
    const [tagList, setTagList] = useState('');//tag
    const excludeEditor = [
        'separator',
        {
            key: 'my-button', // 控件唯一标识，必传
            type: 'button',
            title: '在当前光标处插入NFT素材', // 指定鼠标悬停提示文案
            className: 'insertNFT', // 指定按钮的样式名
            html: null, // 指定在按钮中渲染的html字符串
            text: '插入NFT素材', // 指定按钮文字，此处可传入jsx，若已指定html，则text不会显示
            onClick: () => {
                setInsertNFTVisible(true)
            },
        }
    ]
    useEffect(() => {


    }, [])


    const handleEditorChange = (e) => {
        console.log(e)
        setEditorState(e)
    }

    const titleChange = (e) => {
        setTitle(e.target.value)
    }
    const introductionChange = (e) => {
        setIntroduction(e.target.value)
    }
    const tagChange = (e) => {
        setTagList(e.target.value)
    }
    const save = async () => {
        // let arrNFTID = getExecStrs(editora.getHtml());
        // console.log(arrNFTID)
        // let savehtml;
        if (title == '' || introduction == '' || bannerUrl == '' || featureUrl == '' || editorState.toHTML() == '') {
            message.success('请输入完成数据');
            return;
        }
        let html = editorState.toHTML();
        let arrNFTID = getExecStrs(html);
        console.log(arrNFTID)
        if (arrNFTID.length > 0) {
            await arrNFTID.map((link, nums) => {
                console.log(link)
                console.log(link.substring(find(link, '/', 4) + 1))
                let nid = link.substring(find(link, '/', 4) + 1);

                let formData = {
                    "item_id": parseInt(nid),        //
                }
                const config = {
                    headers: { "Content-Type": "multipart/form-data" }
                };
                axios.post('/api/getItemInfo', formData, config).then(function (response) {

                    if (response.status === 200) {
                        const Data = response.data.data;
                        // message.success('创建专题成功');
                        let currencyType;
                        if (Data.currency_type === 0) {
                            currencyType = '';
                        } else if (Data.currency_type === 1) {
                            currencyType = 'eth';
                        } else if (Data.currency_type === 3) {
                            currencyType = 'usdt';
                        }
                        // Data.currency_type === 1 ? 'eth' : 'weth'
                        let htmls = `<div style="width:280px;height:200px;border:1px solid #eee;border-radius: 10px;overflow: hidden;">
                            <a href=${link} target="_blank">
                            <div style="width:100%;height:160px;background:url(https://image.unemeta.com/${Data.content}) center center no-repeat;background-size:cover;"></div>
                            <div style="position:relative;width:240px;height:40px;">
                                <div style="width: 130px;font-weight: bold;height:40px;font-size: 14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;line-height: 40px;padding-left: 10px;word-break: break-all;">${Data.name}</div>
                                <div style="width: 90px;height:40px;font-size: 12px;position:absolute;right:0;top:0;line-height: 40px;text-align: right;padding-right: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">price:${Data.price} ${currencyType}</div>
                            </div>
                            </a>
                        </div>`
                        html = html.replace(`{{${link}}}`, htmls);
                        // setPreviewHtml(html)
                        if ((arrNFTID.length - 1) === nums) {
                            let formDatas = {
                                "title": title,        //标题
                                "introduction": introduction,        //标题
                                "banner_image": bannerUrl,   //横幅图片
                                "recommend_image": featureUrl,     //推荐图片
                                "content": html,     //内容
                                "tag_list": tagList
                            }
                            const configs = {
                                headers: { "Content-Type": "multipart/form-data" }
                            };
                            axios.post('/api/createAlbum', formDatas, configs).then(function (response) {
                                console.log(response)
                                if (response.status === 200) {
                                    message.success('创建专题成功');
                                } else {
                                    message.error('创建专题失败');
                                }
                            })
                        }

                    } else {
                        message.error('搜索失败');
                    }
                })


                // setPreviewHtml(html)
            })

        } else {
            let formDatas = {
                "title": title,        //标题
                "banner_image": bannerUrl,   //横幅图片
                "recommend_image": featureUrl,     //推荐图片
                "content": html,     //内容
                "tag_list": tagList,
                "introduction": introduction
            }
            const configs = {
                headers: { "Content-Type": "multipart/form-data" }
            };
            axios.post('/api/createAlbum', formDatas, configs).then(function (response) {
                console.log(response)
                if (response.status === 200) {
                    message.success('创建专题成功');
                } else {
                    message.error('创建专题失败');
                }
            })
        }
        await arrNFTID.map((link, nums) => {
            console.log(link)
            console.log(link.substring(find(link, '/', 4) + 1))
            let nid = link.substring(find(link, '/', 4) + 1);

            let formData = {
                "item_id": parseInt(nid),        //
            }
            const config = {
                headers: { "Content-Type": "multipart/form-data" }
            };
            axios.post('/api/getItemInfo', formData, config).then(function (response) {

                if (response.status === 200) {
                    const Data = response.data.data;
                    // message.success('创建专题成功');
                    let currencyType;
                    if (Data.currency_type === 0) {
                        currencyType = '';
                    } else if (Data.currency_type === 1) {
                        currencyType = 'eth';
                    } else if (Data.currency_type === 3) {
                        currencyType = 'usdt';
                    }
                    // Data.currency_type === 1 ? 'eth' : 'weth'
                    let htmls = `<div style="width:280px;height:200px;border:1px solid #eee;border-radius: 10px;overflow: hidden;">
                        <a href=${link} target="_blank">
                        <div style="width:100%;height:160px;background:url(https://image.unemeta.com/${Data.content}) center center no-repeat;background-size:cover;"></div>
                        <div style="position:relative;width:240px;height:40px;">
                            <div style="width: 130px;font-weight: bold;height:40px;font-size: 14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;line-height: 40px;padding-left: 10px;word-break: break-all;">${Data.name}</div>
                            <div style="width: 90px;height:40px;font-size: 12px;position:absolute;right:0;top:0;line-height: 40px;text-align: right;padding-right: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">price:${Data.price} ${currencyType}</div>
                        </div>
                        </a>
                    </div>`
                    html = html.replace(`{{${link}}}`, htmls);
                    // setPreviewHtml(html)
                    if ((arrNFTID.length - 1) === nums) {
                        let formDatas = {
                            "title": title,        //标题
                            "banner_image": bannerUrl,   //横幅图片
                            "recommend_image": featureUrl,     //推荐图片
                            "content": html,     //内容
                            "tag_list": tagList,
                            "introduction": introduction
                        }
                        const configs = {
                            headers: { "Content-Type": "multipart/form-data" }
                        };
                        axios.post('/api/createAlbum', formDatas, configs).then(function (response) {
                            console.log(response)
                            if (response.status === 200) {
                                message.success('创建专题成功');
                            } else {
                                message.error('创建专题失败');
                            }
                        })
                    }

                } else {
                    message.error('搜索失败');
                }
            })


            // setPreviewHtml(html)
        })

        // await arrNFTID.map(nid => {
        //     let formData = {
        //         "item_id": parseInt(nid),        //
        //     }
        //     const config = {
        //         headers: { "Content-Type": "multipart/form-data" }
        //     };
        //     axios.post('/api/getItemInfo', formData, config).then(function (response) {
        //         console.log(response)

        //         if (response.status === 200) {
        //             const Data = response.data.data;
        //             // message.success('创建专题成功');
        //             let currencyType = Data.currency_type === 1 ? 'eth' : 'weth'
        //             let htmls = `<div style="width:240px;height:200px;border:1px solid #eee;border-radius: 10px;overflow: hidden;">
        //                 <a href="http://43.132.201.101/asset/testtrade/40" target="_blank">
        //                 <div style="width:100%;height:160px;background:url(https://image.unemeta.com/${Data.content}) center center no-repeat;background-size:cover;"></div>
        //                 <div style="position:relative;width:240px;height:40px;">
        //                     <div style="width: 130px;font-weight: bold;height:40px;font-size: 14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;line-height: 40px;padding-left: 10px;word-break: break-all;">${Data.name}</div>
        //                     <div style="width: 90px;height:40px;font-size: 12px;position:absolute;right:0;top:0;line-height: 40px;text-align: right;padding-right: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">price:${Data.price} ${currencyType}</div>
        //                 </div>
        //                 </a>
        //             </div>`

        //             savehtml = editora.getHtml().replace(`{{${nid}}}`, htmls);
        //             console.log(savehtml)

        //             let formDatas = {
        //                 "title": title,        //标题
        //                 "banner_image": bannerUrl,   //横幅图片
        //                 "recommend_image": featureUrl,     //推荐图片
        //                 "content": savehtml     //内容
        //             }
        //             const configs = {
        //                 headers: { "Content-Type": "multipart/form-data" }
        //             };
        //             axios.post('/api/createAlbum', formDatas, configs).then(function (response) {
        //                 console.log(response)
        //                 if (response.status === 200) {
        //                     message.success('创建专题成功');
        //                 } else {
        //                     message.error('创建专题失败');
        //                 }
        //             })
        //         } else {
        //             message.error('搜索失败');
        //         }
        //     })
        //     // setPreviewHtml(html)
        // })


    }

    const insertNFTOk = (link) => {
        setInsertNFTVisible(false)
        console.log(link)
        setEditorState(ContentUtils.insertText(editorState, `{{${NFTLink}}}`))

        // editora.insertText(`{{${NFTID}}}`)
        // editora.insertText(`{{${link}}}`)
    }
    const preivewTopic = () => {

        console.log(editorState.toHTML())
        // editorState.toHTML()
        let html = editorState.toHTML();
        let arrNFTID = getExecStrs(html);
        console.log(arrNFTID)
        arrNFTID.map(link => {
            console.log(link)
            console.log(link.substring(find(link, '/', 4) + 1))
            let nid = link.substring(find(link, '/', 4) + 1);
            let formData = {
                "item_id": parseInt(nid),        //
            }
            const config = {
                headers: { "Content-Type": "multipart/form-data" }
            };

            axios.post('/api/getItemInfo', formData, config).then(function (response) {
                console.log(response)

                if (response.status === 200) {
                    const Data = response.data.data;
                    // message.success('创建专题成功');
                    // let currencyType = Data.currency_type === 1 ? 'eth' : 'weth'
                    let currencyType;
                    if (Data.currency_type === 0) {
                        currencyType = '';
                    } else if (Data.currency_type === 1) {
                        currencyType = 'eth';
                    } else if (Data.currency_type === 3) {
                        currencyType = 'usdt';
                    }
                    let htmls = `<div style="width:240px;height:200px;border:1px solid #eee;border-radius: 10px;overflow: hidden;">
                        <a href=${link} target="_blank">
                        <div style="width:100%;height:160px;background:url(https://image.unemeta.com/${Data.content}) center center no-repeat;background-size:cover;"></div>
                        <div style="position:relative;width:240px;height:40px;">
                            <div style="width: 130px;font-weight: bold;height:40px;font-size: 14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;line-height: 40px;padding-left: 10px;word-break: break-all;">${Data.name}</div>
                            <div style="width: 90px;height:40px;font-size: 12px;position:absolute;right:0;top:0;line-height: 40px;text-align: right;padding-right: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">price:${Data.price} ${currencyType}</div>
                        </div>
                        </a>
                    </div>`
                    html = html.replace(`{{${link}}}`, htmls);
                    setPreviewHtml(html)
                } else {
                    message.error('搜索失败');
                }
            })
            // setPreviewHtml(html)
        })

        setPreviewVisible(true)
        setPreviewHtml(editorState.toHTML())
    }

    const insertNFTCancel = () => {
        setInsertNFTVisible(false)
    }
    const insertNFT = () => {

    }
    const onSearchNFT = (link) => {
        console.log(link.substring(find(link, '/', 4) + 1))
        setNFTLink(link)
        //http://43.132.201.101/asset/first/9
        let itemid = link.substring(find(link, '/', 4) + 1);
        setNFTID(itemid)

        let formData = {
            "item_id": parseInt(itemid),        //
        }
        const config = {
            headers: { "Content-Type": "multipart/form-data" }
        };
        axios.post('/api/getItemInfo', formData, config).then(function (response) {
            console.log(response)

            if (response.status === 200) {
                const Data = response.data.data;
                // message.success('创建专题成功');
                let currencyType;
                if (Data.currency_type === 0) {
                    currencyType = '';
                } else if (Data.currency_type === 1) {
                    currencyType = 'eth';
                } else if (Data.currency_type === 3) {
                    currencyType = 'usdt';
                }
                let html = `<div style="width:240px;height:200px;border:1px solid #eee;border-radius: 10px;overflow: hidden;">
                    <div style="width:100%;height:160px;background:url(https://image.unemeta.com/${Data.content}) center center no-repeat;background-size:cover;"></div>
                    <div style="position:relative;width:240px;height:40px;">
                        <div style="width: 130px;font-weight: bold;height:40px;font-size: 14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;line-height: 40px;padding-left: 10px;word-break: break-all;">${Data.name}</div>
                        <div style="width: 90px;height:40px;font-size: 12px;position:absolute;right:0;top:0;line-height: 40px;text-align: right;padding-right: 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">price:${Data.price} ${currencyType}</div>
                    </div>
                </div>`
                setInsertNFTHtml(html)
            } else {
                message.error('搜索失败');
            }
        })
    }
    const find = (str, cha, num) => {
        var x = str.indexOf(cha);
        for (var i = 0; i < num; i++) {
            x = str.indexOf(cha, x + 1);
        }
        return x;
    }
    const checkNFTActive = () => {
        if (checkedNFT === '') {
            setCheckedNFT('checkedNFTShow')
        } else {
            setCheckedNFT('')
        }

    }
    const openInsertNFT = () => {
        setInsertNFTVisible(true)
    }
    const getExecStrs = (str) => {
        var reg = /\{\{(.+?)\}\}/g
        var list = []
        var result = null
        do {
            result = reg.exec(str)
            result && list.push(result[1])
        } while (result)
        return list
    }

    const previewNFTCancel = () => {
        setPreviewVisible(false)
    }
    const myUploadFn = (param) => {

        const serverURL = '/api/upLoadImage'
        const xhr = new XMLHttpRequest
        const fd = new FormData()
        console.log(param)
        const successFn = (response) => {
            console.log(response)
            console.log(xhr)
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            param.success({
                url: JSON.parse(xhr.responseText).data,
                meta: {
                    // id: 'xxx',
                    // title: 'xxx',
                    // alt: 'xxx',
                    loop: true, // 指定音视频是否循环播放
                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                    poster: JSON.parse(xhr.responseText).data, // 指定视频播放器的封面
                }
            })
        }

        const progressFn = (event) => {
            console.log(event)
            // 上传进度发生变化时调用param.progress
            param.progress(event.loaded / event.total * 100)
        }

        const errorFn = (response) => {
            console.log(response)
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.'
            })
        }

        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        console.log(param)
        console.log(fd)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.send(fd)

    }
    return (<div>
        <Layout>
            <Nav index="2" pindex="sub1" />
            <UserLayout>
                <div className="createItem">
                    <div className="createItemTitle">Banner 图片(*推荐1400*400)</div>
                    <UploadImg styles={{ 'width': '20rem', 'height': '160px', 'borderRadius': '1rem' }} urlToken={e => setBannerUrl(e)} />
                </div>
                <div className="createItem">
                    <div className="createItemTitle">首页推荐图片(*推荐690*690)</div>
                    <UploadImg styles={{ 'width': '20rem', 'height': '160px', 'borderRadius': '1rem' }} urlToken={e => setFeatureUrl(e)} />
                </div>
                <div className="createItem">
                    <div className="createItemTitle">专题标题</div>
                    <Input className="createItemInput" onChange={titleChange} type="text" />
                </div>
                <div className="createItem">
                    <div className="createItemTitle">专题简介</div>
                    <textarea className="createItemTextarea" onChange={introductionChange} ></textarea>
                </div>
                <div className="createItem">
                    <div className="createItemTitle">专题Tag</div>
                    <Input className="createItemInput" onChange={tagChange} type="text" />
                    *用逗号(,)分割
                </div>
                <div className="createItem">
                    <div className="createItemTitle">专题内容</div>
                    <BraftEditor
                        value={editorState}
                        className="editorWrapper"
                        onChange={handleEditorChange}
                        extendControls={excludeEditor}
                        media={{ uploadFn: myUploadFn }}
                    />
                    {/* <Button onClick={openInsertNFT} type="primary">插件NFT</Button>
                    <div className="topEditor">
                        <div id="toolbar-container"></div>
                        <div id="editor-container" style={{ 'height': '300px' }}></div>
                    </div> */}

                </div>
                <Space size={30}>
                    <Button onClick={save} size={'large'} type="primary">保存</Button>
                    <Button onClick={preivewTopic} size={'large'}>预览</Button>
                </Space>
                <Modal title="插入NFT素材" visible={insertNFTVisible} onOk={insertNFTOk}
                    onCancel={insertNFTCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={insertNFTOk}>
                            插入NFT素材
                        </Button>,
                    ]}>
                    <div className="searchNFTWrapper">
                        <Search
                            placeholder="Search NFT Link"
                            allowClear
                            enterButton="Search"
                            size="large"
                            onSearch={onSearchNFT}
                        />
                        <div onClick={checkNFTActive} className="insertHtmlWrapper">
                            <div className={checkedNFT}>
                                {checkedNFT !== '' && <CheckCircleFilled style={{ 'color': '#52c41a' }} className="checkedNFTIcon" />}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: insertNFTHtml }}></div>
                        </div>
                    </div>
                </Modal>

                <Modal title="预览" visible={previewVisible}
                    onCancel={previewNFTCancel}
                    width={1000}
                    footer={[
                        <Button key="submit" type="primary" onClick={previewNFTCancel}>
                            关闭
                        </Button>,
                    ]}>
                    <div className="previewTopicWrapper">
                        <div dangerouslySetInnerHTML={{ __html: previewHtml }}></div>
                    </div>
                </Modal>
            </UserLayout>
        </Layout>

    </div>)
}

export default TopicCreate