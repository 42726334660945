import { Layout } from 'antd';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
const { Header, Sider, Content } = Layout;
const UserLayout = ({ children }) => {
    let Navigate = useNavigate();

    useEffect(() => {
        if (Cookies.get('session_id') === undefined) {
            Navigate('/login')
        }
    }, [])


    // const logout = () => {
    //     console.log(2)
    //     Cookies.remove('session_id')
    //     useNavigate('/login')
    // }
    return (
        <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
                <div onClick={() => { Cookies.remove('session_id'); Navigate('/login', { replace: true }) }} className="logout">退出</div>
            </Header>
            <Content
                className="site-layout-background"
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 480,
                }}
            >
                {children}
            </Content>
        </Layout>
    )
}

export default UserLayout;